<template>
  <div>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        temporary
        v-model="setting_dialog"
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-row>
                <v-subheader class="success--text" style="border-bottom: 1px solid var(--v-success-base); width: 100%; height: 40px; padding-top: 6px">
                  Налаштування друку
                </v-subheader>
                <v-col cols="12" md="6">
                  <v-switch hide-details
                            color="grey darken-1"
                            v-model="document_setting.duplicate_on_one_page"
                            label="2 копії на одну сторінку"
                            class="mt-0"
                            @change="duplicateOnOnePageChange"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch hide-details
                            color="grey darken-1"
                            v-model="document_setting.duplicate_on_differance_page"
                            label="2 копії на різні сторінки"
                            class="mt-0"
                            @change="duplicateOnDifferancePageChange"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-switch hide-details
                            color="grey darken-1"
                            v-model="document_setting.show_signature"
                            label="Виводити підписи"
                            class="mt-0"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea color="success"
                              label="Додатковий текст"
                              auto-grow
                              rows="2"
                              placeholder="Введіть бажаний текст для відображення у документі"
                              v-model="document_setting.additional_text"
                              hide-details
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page page-a4-landscape-auto" style="height: auto !important; min-height: 215mm">
      <div class="document-print-form">
        <template v-if="!document_setting.duplicate_on_differance_page && !document_setting.duplicate_on_one_page">
          <table class="document-table-form" v-if="1">
            <caption class="mb-3">
              <div class="d-flex">
                <div style="flex: 0 0 70%;">
                  <div style="border-bottom: 1px solid black; font-weight: bold">
                    {{ document_data.organization_full_name }}
                  </div>
                  <div>
                    Найменування підприємства (установи, організації)
                  </div>
                  <div style="border-bottom: 1px solid black; font-weight: bold; margin-top: 8px; min-height: 18px">
                    {{ document_data.subdivision_name }}
                  </div>
                  <div>
                    назва структурного підрозділу
                  </div>
                  <div class="d-flex" style=" margin-top: 8px">
                    <div style="flex: 0 0 200px; text-align: left">
                      Ідентифікаційний код ЄДРПОУ
                    </div>
                    <div style="border-bottom: 1px solid black; flex: 0 0 250px">
                      {{ document_data.organization_code }}
                    </div>
                  </div>
                </div>
                <div style="flex: 1;">
                  <div class="d-flex" style="font-size: .67rem; line-height: .74rem">
                    <div style="flex: 0 0 200px"></div>
                    <div style="flex: 1; text-align: left">
                      Типова форма № П-5 <br>
                      ЗАТВЕРДЖЕНО <br>
                      Наказ Держкомстату України <br>
                      05.12.2008 № 489
                    </div>
                  </div>
                  <div class="d-flex" style="margin-top: 12px; font-size: .68rem">
                    <div style="flex: 0 0 40px"></div>
                    <div style="flex: 1">
                      <div class="d-flex flex-nowrap">
                        <div style="flex: 0 0 40%;
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                        border-bottom: 1px solid black;
                        border-top: 1px solid black">
                            Дата заповнення
                        </div>
                        <div style="flex: 1;
                        border-top: 1px solid black;
                        border-right: 1px solid black;
                        border-bottom: 1px solid black;
                        border-top: 1px solid black">
                            <div>Звітній період</div>
                        </div>
                      </div>
                      <div class="d-flex flex-nowrap">
                        <div style="flex: 0 0 40%;
                        border-bottom: 1px solid black;
                        border-right: 1px solid black;
                        font-weight: bold;
                        border-left: 1px solid black;">
                            <div class="d-flex align-center" style="height: 100%; justify-content: center">
                              {{ document_data.date | formatDate }}
                            </div>
                        </div>
                        <div style="flex: 1">
                          <div class="d-flex flex-nowrap">
                            <div style="flex: 0 0 50%; border-bottom: 1px solid black">
                              з
                            </div>
                            <div style="flex: 1; border-right: 1px solid black;
                            border-bottom: 1px solid black;
                             border-left: 1px solid black;">
                              по
                            </div>
                          </div>
                          <div class="d-flex flex-nowrap">
                            <div style="flex: 0 0 50%; border-bottom: 1px solid black; font-weight: bold">
                              {{ document_data.date_start | formatDate }}
                            </div>
                            <div style="flex: 1; border-bottom: 1px solid black; font-weight: bold;
                            border-left: 1px solid black;
                            border-right: 1px solid black">
                              {{ document_data.date_end | formatDate }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="font-size: 1.1rem; font-weight: bold; margin-top: 16px">
                ТАБЕЛЬ ОБЛІКУ ВИКОРИСТАННЯ РОБОЧОГО ЧАСУ
              </div>
            </caption>
            <thead>
              <tr>
                <th rowspan="4" style="width: 30px; max-width: 30px; min-width: 30px" class="vertical-text">
                  <span>№ п/п</span>
                </th>
                <th rowspan="4" style="width: 62px; max-width: 62px; min-width: 62px" class="vertical-text">
                  <span>Табельний номер</span>
                </th>
                <th rowspan="4" style="width: 17px; max-width: 17px; min-width: 17px" class="vertical-text">
                  <span>Стать</span>
                </th>
                <th rowspan="4" style="width: 140px; max-width: 140px; min-width: 140px">
                  ПІБ, посада
                </th>
                <th rowspan="1" colspan="16">
                  Відмітки про явки та неявки за числами місяця (годин)
                </th>
                <th rowspan="1" colspan="6">
                  Відпрацьовано за місяць
                </th>
                <th rowspan="2" colspan="1" style="width: 60px; max-width: 60px">
                  Всього неявок
                </th>
                <th rowspan="1" colspan="12">
                  з причин за місяць
                </th>
                <th rowspan="4" style="width: 69px; max-width: 69px; min-width: 69px">
                  Оклад,
                  тарифна
                  ставка, грн
                </th>
              </tr>
              <tr>
                <th rowspan="2" class="day-col">01</th>
                <th rowspan="2" class="day-col">02</th>
                <th rowspan="2" class="day-col">03</th>
                <th rowspan="2" class="day-col">04</th>
                <th rowspan="2" class="day-col">05</th>
                <th rowspan="2" class="day-col">06</th>
                <th rowspan="2" class="day-col">07</th>
                <th rowspan="2" class="day-col">08</th>
                <th rowspan="2" class="day-col">09</th>
                <th rowspan="2" class="day-col">10</th>
                <th rowspan="2" class="day-col">11</th>
                <th rowspan="2" class="day-col">12</th>
                <th rowspan="2" class="day-col">13</th>
                <th rowspan="2" class="day-col">14</th>
                <th rowspan="2" class="day-col">15</th>
                <th rowspan="2" class="day-col">x</th>
                <th rowspan="3" class="vertical-text" style="width: 21px; max-width: 21px; min-width: 21px">
                  <span>днів</span>
                </th>
                <th colspan="5">
                  годин
                </th>
                <th rowspan="1" class="reason-col">
                  основна
                  та
                  додат- <br>
                  кова від- <br>
                  пустки
                </th>
                <th rowspan="1" class="reason-col">
                  відпуст- <br>
                  ки у зв’яз- <br>
                  ку з нав- <br>
                  чанням, творчі, в
                  обов.
                  порядку
                  та інші
                </th>
                <th rowspan="1" class="reason-col">
                  відпуст- <br>
                  ки без збере- <br>
                  ження заробіт- <br>
                  ної плати за
                  згодою
                  сторін
                </th>
                <th rowspan="1" class="reason-col">
                  відпуст- <br>
                  ки без збере- <br>
                  ження з/п
                  на період
                  припи- <br>
                  нення викона <br>
                  ння робіт
                </th>
                <th rowspan="1" class="reason-col">
                  перевод
                  на
                  непов- <br>
                  ний робочий
                  день
                  (тиж- <br>
                  день)
                </th>
                <th rowspan="1" class="reason-col">
                  тимча- <br>
                  совий перевод
                  на інше
                  підпри- <br>
                  ємство
                </th>
                <th rowspan="1" class="reason-col">
                  простої
                </th>
                <th rowspan="1" class="reason-col">
                  прогули
                </th>
                <th rowspan="1" class="reason-col">
                  страйки
                </th>
                <th rowspan="1" class="reason-col">
                  тимча-<br> сова
                  непра-<br>
                  цездат-<br>ність
                </th>
                <th rowspan="1" class="reason-col">
                  інші
                </th>
                <th rowspan="1" class="reason-col" style="min-width: 20px"></th>
              </tr>
              <tr>
                <th rowspan="2" class="vertical-text" style="min-width: 24px; max-width: 24px; width: 24px">
                  <span>всього</span>
                </th>
                <th rowspan="1" colspan="4">з них:</th>
                <th rowspan="1" colspan="1">години</th>
                <th rowspan="1" colspan="1">коди <br> 8-10</th>
                <th rowspan="1" colspan="1">коди <br> 11-15,<br> 17,22</th>
                <th rowspan="1" colspan="1">коди<br>18</th>
                <th rowspan="1" colspan="1">коди<br>19</th>
                <th rowspan="1" colspan="1">коди<br>20</th>
                <th rowspan="1" colspan="1">коди<br>21</th>
                <th rowspan="1" colspan="1">коди<br>23</th>
                <th rowspan="1" colspan="1">коди<br>24</th>
                <th rowspan="1" colspan="1">коди<br>25</th>
                <th rowspan="1" colspan="1">коди<br>26-27</th>
                <th rowspan="1" colspan="1">коди<br>28-30</th>
                <th rowspan="1" colspan="1"></th>
              </tr>
              <tr>
                <th rowspan="1" class="day-col">16</th>
                <th rowspan="1" class="day-col">17</th>
                <th rowspan="1" class="day-col">18</th>
                <th rowspan="1" class="day-col">19</th>
                <th rowspan="1" class="day-col">20</th>
                <th rowspan="1" class="day-col">21</th>
                <th rowspan="1" class="day-col">22</th>
                <th rowspan="1" class="day-col">23</th>
                <th rowspan="1" class="day-col">24</th>
                <th rowspan="1" class="day-col">25</th>
                <th rowspan="1" class="day-col">26</th>
                <th rowspan="1" class="day-col">27</th>
                <th rowspan="1" class="day-col">28</th>
                <th rowspan="1" class="day-col">29</th>
                <th rowspan="1" class="day-col">30</th>
                <th rowspan="1" class="day-col">31</th>
                <th rowspan="1" class="reason-col-add">
                  над-<br>урочно
                </th>
                <th rowspan="1" class="reason-col-add">
                  ніч-<br>них
                </th>
                <th rowspan="1" class="reason-col-add">
                  вечір-<br>них
                </th>
                <th rowspan="1" class="reason-col-add">
                  вихідних,<br>
                  святко-<br>вих
                </th>
                <th>дні</th>
                <th>дні/год.</th>
                <th>дні/год.</th>
                <th>дні/год.</th>
                <th>дні/год.</th>
                <th>дні/год.</th>
                <th>дні/год.</th>
                <th>дні/год.</th>
                <th>дні/год.</th>
                <th>дні/год.</th>
                <th>дні/год.</th>
                <th>дні/год.</th>
                <th>дні/год.</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, idx) in document_data.table">
                <tr :key="`row-1-${idx}`">
                  <td rowspan="4">{{ idx + 1 }}</td>
                  <td rowspan="4">{{ item.tub_number }}</td>
                  <td rowspan="4">{{ item.sex_type }}</td>
                  <td rowspan="4">
                    {{ item.short_name }}, {{ item.position_name }}
                  </td>
                  <td rowspan="1">{{ item.days['1'].letter }}</td>
                  <td rowspan="1">{{ item.days['2'].letter }}</td>
                  <td rowspan="1">{{ item.days['3'].letter }}</td>
                  <td rowspan="1">{{ item.days['4'].letter }}</td>
                  <td rowspan="1">{{ item.days['5'].letter }}</td>
                  <td rowspan="1">{{ item.days['6'].letter }}</td>
                  <td rowspan="1">{{ item.days['7'].letter }}</td>
                  <td rowspan="1">{{ item.days['8'].letter }}</td>
                  <td rowspan="1">{{ item.days['9'].letter }}</td>
                  <td rowspan="1">{{ item.days['10'].letter }}</td>
                  <td rowspan="1">{{ item.days['11'].letter }}</td>
                  <td rowspan="1">{{ item.days['12'].letter }}</td>
                  <td rowspan="1">{{ item.days['13'].letter }}</td>
                  <td rowspan="1">{{ item.days['14'].letter }}</td>
                  <td rowspan="1">{{ item.days['15'].letter }}</td>
                  <td rowspan="1">x</td>
                  <td rowspan="4">{{ item.work_days }}</td>
                  <td rowspan="4">{{ item.work_hours }}</td>
                  <td rowspan="4">{{ item.above_work || "" }}</td>
                  <td rowspan="4">{{ item.night || "" }}</td>
                  <td rowspan="4">{{ item.evening || "" }}</td>
                  <td rowspan="4">{{ item.weekday || "" }}</td>
                  <td rowspan="2">{{ item.not_coming_hours || "" }}</td>
                  <td rowspan="2">{{ item.code_8_10 || "" }}</td>
                  <td rowspan="2">{{ item.code_11_15 || "" }}</td>
                  <td rowspan="2">{{ item.code_18 || "" }}</td>
                  <td rowspan="2">{{ item.code_19 || "" }}</td>
                  <td rowspan="2">{{ item.code_20 || "" }}</td>
                  <td rowspan="2">{{ item.code_21 || "" }}</td>
                  <td rowspan="2">{{ item.code_23 || "" }}</td>
                  <td rowspan="2">{{ item.code_24 || "" }}</td>
                  <td rowspan="2">{{ item.code_25 || "" }}</td>
                  <td rowspan="2">{{ item.code_26_27 || "" }}</td>
                  <td rowspan="2">{{ item.code_28_30 || "" }}</td>
                  <td rowspan="2"></td>
                  <td rowspan="4">{{ item.sum | formatToFixed | formatNumber }}</td>
                </tr>
                <tr :key="`row-2-${idx}`">
                  <td rowspan="1">{{ item.days['1'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['2'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['3'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['4'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['5'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['6'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['7'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['8'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['9'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['10'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['11'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['12'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['13'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['14'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['15'].hours || "" }}</td>
                  <td rowspan="1">x</td>
                </tr>
                <tr :key="`row-3-${idx}`">
                  <td rowspan="1">{{ item.days['16'].letter }}</td>
                  <td rowspan="1">{{ item.days['17'].letter }}</td>
                  <td rowspan="1">{{ item.days['18'].letter }}</td>
                  <td rowspan="1">{{ item.days['19'].letter }}</td>
                  <td rowspan="1">{{ item.days['20'].letter }}</td>
                  <td rowspan="1">{{ item.days['21'].letter }}</td>
                  <td rowspan="1">{{ item.days['22'].letter }}</td>
                  <td rowspan="1">{{ item.days['23'].letter }}</td>
                  <td rowspan="1">{{ item.days['24'].letter }}</td>
                  <td rowspan="1">{{ item.days['25'].letter }}</td>
                  <td rowspan="1">{{ item.days['26'].letter }}</td>
                  <td rowspan="1">{{ item.days['27'].letter }}</td>
                  <td rowspan="1">{{ item.days['28'].letter }}</td>
                  <td rowspan="1">{{ item.days['29'].letter }}</td>
                  <td rowspan="1">{{ item.days['30'].letter }}</td>
                  <td rowspan="1">{{ item.days['31'].letter }}</td>
                  <td rowspan="2">{{ item.not_coming_days || "" }}</td>
                  <td rowspan="2">{{ item.code_8_10_days || "" }}</td>
                  <td rowspan="2">{{ item.code_11_15_days || "" }}</td>
                  <td rowspan="2">{{ item.code_18_days || "" }}</td>
                  <td rowspan="2">{{ item.code_19_days || "" }}</td>
                  <td rowspan="2">{{ item.code_20_days || "" }}</td>
                  <td rowspan="2">{{ item.code_21_days || "" }}</td>
                  <td rowspan="2">{{ item.code_23_days || "" }}</td>
                  <td rowspan="2">{{ item.code_24_days || "" }}</td>
                  <td rowspan="2">{{ item.code_25_days || "" }}</td>
                  <td rowspan="2">{{ item.code_26_27_days || "" }}</td>
                  <td rowspan="2">{{ item.code_28_30_days || "" }}</td>
                  <td rowspan="2"></td>
                </tr>
                <tr :key="`row-4-${idx}`">
                  <td rowspan="1">{{ item.days['16'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['17'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['18'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['19'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['20'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['21'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['22'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['23'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['24'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['25'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['26'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['27'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['28'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['29'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['30'].hours || "" }}</td>
                  <td rowspan="1">{{ item.days['31'].hours || "" }}</td>
                </tr>
              </template>
              <tr>
                <td colspan="20" style="font-weight: bold; text-align: right; padding-right: 2px; text-transform: uppercase">
                  Разом:
                </td>
                <td>{{ document_data.total_work_days }}</td>
                <td>{{ document_data.total_work_hours }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
            <tfoot>
              <tr style="margin-top: 8px" class="signature-row">
                <td colspan="15">
                  <div class="d-flex">
                    <div style="flex: 0 0 172px">
                      Відповідальна особа
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">

                    </div>
                  </div>
                </td>
                <td colspan="2"></td>
                <td colspan="13">
                  <div class="d-flex">
                    <div style="flex: 0 0 172px">
                      Керівник структурного підрозділу
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">

                    </div>
                  </div>
                </td>
                <td colspan="1"></td>
                <td colspan="9">
                  <div class="d-flex">
                    <div style="flex: 0 0 172px">
                      Працівник кадрової служби
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">

                    </div>
                  </div>
                </td>
              </tr>
              <tr style="margin-top: 4px" class="signature-row">
                <td colspan="15">
                  <div class="d-flex">
                    <div style="flex: 0 0 172px">
                      "____" _____________ 202__ р.
                    </div>
                    <div style="flex: 0 0 100px; border-bottom: 1px solid black; margin-right: 10px">

                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">

                    </div>
                  </div>
                  <div class="d-flex">
                    <div style="flex: 0 0 172px">
                    </div>
                    <div style="flex: 0 0 100px; text-align: center; font-weight: normal !important; margin-right: 10px">(підпис)</div>
                    <div style="flex: 1; text-align: center; font-weight: normal !important;">(ПІБ)</div>
                  </div>
                </td>
                <td colspan="2"></td>
                <td colspan="13">
                  <div class="d-flex">
                    <div style="flex: 0 0 172px">
                      "____" _____________ 202__ р.
                    </div>
                    <div style="flex: 0 0 100px; border-bottom: 1px solid black; margin-right: 10px">

                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">

                    </div>
                  </div>
                  <div class="d-flex">
                    <div style="flex: 0 0 172px">
                    </div>
                    <div style="flex: 0 0 100px; text-align: center; font-weight: normal !important; margin-right: 10px">(підпис)</div>
                    <div style="flex: 1; text-align: center; font-weight: normal !important;">(ПІБ)</div>
                  </div>
                </td>
                <td colspan="1"></td>
                <td colspan="9">
                  <div class="d-flex">
                    <div style="flex: 0 0 172px">
                      "____" _____________ 202__ р.
                    </div>
                    <div style="flex: 0 0 100px; border-bottom: 1px solid black; margin-right: 10px">

                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">

                    </div>
                  </div>
                  <div class="d-flex">
                    <div style="flex: 0 0 172px">
                    </div>
                    <div style="flex: 0 0 100px; text-align: center; font-weight: normal !important;margin-right: 10px">(підпис)</div>
                    <div style="flex: 1; text-align: center; font-weight: normal !important;">(ПІБ)</div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import documentPrintAPI from "@/utils/axios/accounting/documents/personal_timesheet"
import {formatDate} from "@/filters";

export default {
  name: "invoice_warehouse_income_v1",
  props: {
    organization: {
      type: Object,
      default() {
        return {}
      }
    },
    print_data: {
      type: Object,
      default() {
        return {}
      }
    },
    document_id: {
      type: Number,
      default: null
    },
    global_settings: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    }
  },
  data() {
    return {
      setting_dialog: this.global_settings,
      settings_watcher: null,
      document_setting: {
        additional_text: '',
        show_signature: true,
        duplicate_on_one_page: false,
        duplicate_on_differance_page: false
      },
      document_data: {}
    }
  },
  methods: {
    duplicateOnOnePageChange(payload) {
      if (payload) {
        this.document_setting.duplicate_on_differance_page = false
      }
    },
    duplicateOnDifferancePageChange(payload) {
      if (payload) {
        this.document_setting.duplicate_on_one_page = false
      }
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    formatDate,
    getResponsePerson(table, category, field='short_name') {
      if (table === 'organization') {
        return ((this.print_data?.response_people || []).find(i => i.category === category) || {})?.[field] || ''
      } else {
        return (this.document_data?.contractor_print_data || {})?.response_persons?.[category]?.[field] || ''
      }
    },
  },
  watch: {
    modal: {
      immediate: true,
      handler(payload) {
        this.document_setting.additional_text = ''

        if (payload) {
          documentPrintAPI.get_document_for_print(this.document_id)
              .then(response => response.data)
              .then(data => this.document_data = data)
        } else {
          this.document_data = {}
        }

      }
    }
  },
  created() {
    this.watch_settings()
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
.vertical-text{
  span {
    writing-mode: vertical-lr;
    text-orientation: sideways-right;
    transform: rotate(180deg);
    text-align: left;
  }
}
.day-col {
  width: 17px;
  max-width: 17px;
  min-width: 17px;
}
.reason-col {
  width: 44px;
  max-width: 44px;
  min-width: 44px;
}
.reason-col-add {
  width: 48px;
  max-width: 48px;
}
.signature-row {
  td {
    padding-top: 8px;
    font-weight: bold;
  }
}

.document-print-form {
  width: 100%;
  font-family: Arial, Calibri, serif;
  padding: 8px 4px;
  font-size: 11px;

  .document-table-form {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        border: 1px solid grey;

        th {
          border: 1px solid grey;
          padding: 1px;
          font-size: .63rem;
          line-height: .7rem;
          font-weight: normal;
        }
      }
    }

    tbody {
      tr {
        border: 1px solid grey;

        td {
          border: 1px solid grey;
          padding: 1px;
          font-size: .63rem;
          line-height: .7rem;
          text-align: center;
        }
      }

      .without-border {
        border: none !important;

        td {
          border: none !important;
        }
      }

      .total {
        border: none !important;

        td {
          font-family: "Roboto", sans-serif;
          font-weight: bold;
          font-size: .83rem;
          border: none !important;
        }
      }
    }
  }

  .document-title {
    font-size: 1.02rem;
    font-weight: 600;
    text-align: left;
    line-height: 1.3rem;
  }

  .title-underline {
    border-bottom: 2px solid;
  }

  .text-ident {
    text-indent: 30px;
  }

  .document-body {
    font-size: .8rem;
    text-align: justify;
    margin-top: 12px;
    font-weight: 500;
  }
}
</style>